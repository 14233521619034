<template>
  <div>
    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div role="alert" class="alert alert-info">
          <div class="alert-text">
            Uygulamayı kullanmak için önce <strong>digitalyna.com</strong>
            üzerinden giriş yapmanız gerekmektedir.
          </div>
        </div>
        <!--begin::Divider-->
        <div class="kt-login__divider">
          <div class="kt-divider">
            <span></span>
            <span></span>
          </div>
        </div>
        <!--end::Divider-->

        <!--begin::Options-->
        <div class="kt-login__options">
          <a
            href="https://www.digitalayna.com/"
            class="btn btn-outline-dark kt-btn"
            v-if="!development"
          >
            <fa icon="chevron-left" class="mr-2" />
            Digital Ayna'ya gitmek için tıklayın
          </a>
          <template v-else>
            <b-list-group class="w-100 text-center">
              <b-list-group-item
                button
                v-for="auth in authList"
                :key="auth.key"
                @click="() => beLogin(auth)"
              >
                {{ auth.username }}
              </b-list-group-item>
            </b-list-group>
          </template>
        </div>
        <!--end::Options-->
      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
  </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import { mapState, mapActions } from "vuex";

import authMock from "@/common/mock/auth.json";
import { LOGIN } from "@/store/auth.module";
import { isProduction } from "@/common/config";

export default {
  name: "login",
  methods: {
    ...mapActions([LOGIN]),
    beLogin(auth) {
      this[LOGIN](auth.key);
      this.$router.push("/");
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return `${process.env.BASE_URL}assets/media/bg/bg-4.jpg`;
    },
    development() {
      return !isProduction();
    },
    authList() {
      return Object.keys(authMock).map(key => {
        const user = authMock[key];
        let role;
        if (user.ID_OGRENCI) {
          role = "Öğr.";
        } else if (user.ID_VELI) {
          role = "Veli";
        } else {
          role = "Yön.";
        }
        return {
          username: `${user.AD} (${role})`,
          key
        };
      });
    }
  }
};
</script>
